import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useRelativeTime } from '../../../../../hooks/use-relative-time';
import TimeBlobSvg from '../../images/time-blob.svg';

const StyledTimeBlob = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;
type EventStatusProps = {
  isNow: boolean;
};
const EventStatus = styled.div<EventStatusProps>`
  display: flex;
  flex-direction: row;
  margin: 2px;
  color: ${({ isNow }) => (isNow ? '#ff8d65' : '#4C3B51')};
`;
const EventStatusTitle = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13.3654px;
  line-height: 148%;
`;

interface ExperienceEventTimeProps {
  time: string;
}

export const ExperienceEventTime = ({ time }: ExperienceEventTimeProps): React.ReactElement => {
  const [relativeTime, { isNow }] = useRelativeTime(time);
  if (!relativeTime) {
    return <></>;
  }
  return (
    <EventStatus isNow={isNow!}>
      <StyledTimeBlob>
        <TimeBlobSvg />
      </StyledTimeBlob>
      <EventStatusTitle>{relativeTime}</EventStatusTitle>
    </EventStatus>
  );
};
