import { BoxAvatar, GroupAvatar, SingleAvatar, VideoAvatar } from '@/components/avatars/avatars';
import { ExperienceCardFieldsFragment, ExperienceMode, ExperienceType } from '@/generated/graphql';
import styled from '@emotion/styled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WifiIcon from '@mui/icons-material/Wifi';
import { experienceTypeLabel } from '../../../constants/experience-type-label';
import StatusButton from '../card-buttons/status-button';

const ButtonGroup = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  top: 10px;
  right: 20px;
`;

interface ExperienceTagsProps {
  experience: ExperienceCardFieldsFragment;
}

export const ExperienceTags = ({ experience, ...props }: ExperienceTagsProps) => {
  const { type, mode } = experience;
  const getStartIcon = () => {
    switch (type) {
      case ExperienceType.Schedule:
        return <SingleAvatar color={'#231727'} />;
      case ExperienceType.Event:
        return <GroupAvatar color={'#231727'} />;
      case ExperienceType.Video:
        return <VideoAvatar color={'#231727'} />;
      case ExperienceType.Box:
        return <BoxAvatar color={'#231727'} />;
      default:
        return null;
    }
  };
  return (
    <ButtonGroup {...props}>
      {type && type !== ExperienceType.Undefined && (
        <StatusButton startIcon={getStartIcon()} experience={experience}>
          {experienceTypeLabel[type]}
        </StatusButton>
      )}
      {type !== ExperienceType.Box && type !== undefined && (
        <StatusButton
          startIcon={mode === ExperienceMode.Online ? <WifiIcon /> : <LocationOnIcon />}
          experience={experience}
        >
          {mode === ExperienceMode.Online ? 'Online' : 'In Person'}
        </StatusButton>
      )}
    </ButtonGroup>
  );
};
