import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  ButtonBase,
} from '@mui/material';
import styled from '@emotion/styled';
import omit from 'lodash/omit';
import { forwardRef } from 'react';

const LoadingContainer = styled.span`
  margin-right: 20px;
`;

type LoadingProps = {
  children?: React.ReactNode | React.ReactNode[] | string | React.ReactNode;
};

const Loading = ({ children }: LoadingProps): React.ReactElement => {
  return (
    <>
      {children ? <LoadingContainer>{children}</LoadingContainer> : null}
      <CircularProgress size={30} color="inherit" />
    </>
  );
};

export type ButtonProps = MuiButtonProps & {
  buttonColor?: string;
  labelColor?: string;
  label?: string;
  isBorder?: boolean;
  primary?: boolean;
  size?: 'small' | 'medium' | 'large';
  loading?: boolean;
  children?: React.ReactNode | React.ReactNode[] | string | React.ReactNode;
  target?: string;
};

const MUIButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ label, isBorder, children, loading, startIcon, ...rest }, ref) => (
    <MuiButton
      ref={ref}
      // component={href ? Link : undefined}
      variant={isBorder ? 'outlined' : 'contained'}
      disabled={loading}
      startIcon={loading ? null : startIcon}
      {...omit(rest, ['primary', 'active'])}
    >
      {loading ? <Loading>{children || label}</Loading> : <>{children || label}</>}
    </MuiButton>
  ),
);

MUIButton.displayName = 'MUIButton';

export const Button = styled(MUIButton)`
  border-radius: 28px;
  font-weight: 600 !important;
  text-transform: none;
  max-height: 53px;
  min-height: ${props => {
    const small = props.size === 'small';
    if (small) {
      return '36px';
    }
    return props.primary === false ? '45px' : '53px';
  }};
  padding: ${props => {
    const small = props.size === 'small';
    if (small) {
      return '0px 24px';
    }
    return props.primary === false ? '9px 32px' : '13px 32px';
  }};
  font: 600 17px var(--font-work-sans);
  min-width: 70px;
  line-height: 157.3%;
  z-index: 9;
  box-shadow: none;
  :hover {
    box-shadow: none;
  }

  &.Mui-disabled {
    background-color: #5f5f5f;
    color: #fdf3de;
    box-shadow: none;
    cursor: wait;
  }
`;
export const YellowButton = styled(Button)`
  color: #231727 !important;
  background-color: #ffcf31;
  text-decoration: none;
  :hover {
    background-color: #f0926e;
  }
`;
export const CreamButton = styled(Button)`
  background-color: #fdf3de;
  color: ${({ theme }) => theme.palette.common.midnight};
  :hover {
    background-color: #f0926e;
  }
`;
export const MidnightButton = styled(Button)`
  background-color: #231727;
  color: #fdf3de;
  :hover {
    background-color: #7a3b6e;
  }
`;
export const PlumButton = styled(Button)`
  background-color: #4c3b51;
  color: #fdf3de;
  :hover {
    background-color: #231727;
  }
`;

export const DestroyButton = styled(Button)`
  background-color: #eb5757;
  color: #fff3dc;
  :hover {
    background-color: #a53c3c;
  }
`;

export const BorderedButton = styled(Button)`
  background-color: white;
  color: #231727;
  border: 2px solid #231727;
  :hover {
    color: #fdf3de;
    /* border: 2px solid #7a3b6e; */
    background-color: #231727;
  }
`;
export const BorderedTransparentButton = styled(Button)`
  background-color: transparent;
  color: #fdf3de;
  border: 2px solid #fdf3de;
  :hover {
    color: #f1caba;
    border: 2px solid #f1caba;
    background-color: transparent;
  }
`;
export const BorderedYellowButton = styled(Button)`
  background-color: transparent;
  color: #ffcf31;
  border: 2px solid #ffcf31;
  :hover {
    color: #f0926e;
    border: 2px solid #f0926e;
    background-color: transparent;
  }
`;

export const TextButton = styled(ButtonBase)`
  font-family: var(--font-work-sans);
  text-transform: none;
  padding: 0;
  margin: 0;
  width: auto;
  min-width: auto;
  display: inline;
  text-align: inherit;
  border-radius: 2px;

  &:focus {
    box-shadow:
      0 0 0px 2px ${({ theme }) => theme?.palette?.common.yellow},
      0 0 0px 3px white;
  }
`;
export const RescheduleButton = styled(Button)`
  background-color: #231727;
  color: #ffffff;
  :hover {
    background-color: #231727;
  }
`;
export const CancelButton = styled(Button)`
  background-color: transparent;
  color: #231727;
  border: 2px solid #231727;
  :hover {
    background-color: transparent;
  }
`;

export const MessageHostButton = styled(Button)`
  background-color: transparent;
  color: #231727;
  border: 2px solid #231727;
  border-radius: 50px;
  min-width: 238px;
  min-height: 48px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  :hover {
    background-color: transparent;
  }
`;

export const SeeProfileButton = styled(Button)`
  background-color: #231727;
  color: #fdf3de;
  border-radius: 50px;
  min-width: 223px;
  min-height: 48px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  :hover {
    background-color: transparent;
  }
`;

export default Button;
