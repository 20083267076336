import styled from '@emotion/styled';

const CustomAvatar = styled.div`
  width: 10px;
  height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CustomGroupAvatar = styled(CustomAvatar)`
  width: 14.87px;
  height: 13.6px;
`;

const CustomVideoAvatar = styled(CustomAvatar)`
  width: 17px;
  height: 18px;
`;

const CustomBoxAvatar = styled(CustomAvatar)`
  width: 15px;
  height: 16px;
`;

export interface AvatarProps {
  color: string;
}
export const SingleAvatar = ({ color }: AvatarProps) => {
  return (
    <CustomAvatar>
      <svg
        width="10"
        height="18"
        viewBox="0 0 11 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.31468 12.9928L7.48483 18.9952C4.88546 18.9044 2.19458 18.9044 0.132812 18.9384L0.159979 13.905C0.094208 12.4977 1.14225 10.7329 2.70645 10.6605L4.36501 10.5825C6.16656 10.2945 7.24891 11.5883 7.31468 12.9928Z"
          fill={color}
        />
        <path
          d="M5.25087 9.14846C5.73006 8.7913 6.04668 8.25995 6.13114 7.67122C6.2156 7.0825 6.06097 6.48459 5.70125 6.00895C4.95204 5.01589 3.32636 4.54206 2.32836 5.28544C1.33036 6.02882 1.13448 7.86599 1.87798 8.85621C2.62147 9.84644 4.25287 9.89184 5.25087 9.14846Z"
          fill={color}
        />
        <path
          d="M7.18262 5.49698L9.87207 4.54932L10.1337 5.3693L7.31559 6.00628L7.18262 5.49698Z"
          fill={color}
        />
        <path
          d="M6.16797 4.43582L8.40846 1.8042L9.16339 2.55751L6.56116 4.85858L6.16797 4.43582Z"
          fill={color}
        />
        <path
          d="M4.82617 3.64992L5.89566 0.995605L6.43326 1.23678L5.27084 3.81733L4.82617 3.64992Z"
          fill={color}
        />
      </svg>
    </CustomAvatar>
  );
};

export const GroupAvatar = ({ color }: AvatarProps) => {
  return (
    <CustomGroupAvatar>
      <svg
        width="14.8"
        height="14"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9526 6.55494L11.0792 12.9368C9.14701 12.8681 7.14809 12.867 5.61523 12.8937L5.63595 7.2445C5.58646 6.17988 6.36555 4.84154 7.5267 4.78679L8.76035 4.72739C10.0987 4.51306 10.9031 5.49032 10.9526 6.55494Z"
          fill={color}
        />
        <path
          d="M9.42083 3.64009C9.77681 3.36932 10.012 2.9666 10.0748 2.5204C10.1376 2.0742 10.0228 1.62102 9.75571 1.26042C9.19873 0.509127 7.9904 0.148041 7.24929 0.711801C6.50818 1.27556 6.35742 2.66865 6.91441 3.41878C7.47139 4.16891 8.67857 4.20385 9.42083 3.64009Z"
          fill={color}
        />
        <path
          d="M3.21634 6.24117L2.29571 6.28427C1.43606 6.32504 0.856065 7.31977 0.89289 8.11183L0.87793 13.6912C2.01721 13.6725 3.50404 13.6725 4.94023 13.7238L4.84701 7.59932C4.81019 6.80843 4.21523 6.0781 3.21634 6.24117Z"
          fill={color}
        />
        <path
          d="M3.72698 5.38487C4.05472 5.13584 4.27133 4.76525 4.32916 4.3546C4.387 3.94395 4.28133 3.52686 4.03539 3.19506C3.52214 2.5055 2.41162 2.17353 1.7338 2.69187C1.05599 3.2102 0.909836 4.49031 1.42194 5.18103C1.93404 5.87175 3.04456 5.9032 3.72698 5.38487Z"
          fill={color}
        />
        <path
          d="M15.6506 9.11457C15.6137 8.32251 15.0153 7.59335 14.0199 7.75526L13.0992 7.79952C12.2384 7.84029 11.6596 8.83502 11.6976 9.62708L11.6826 14.0032C12.8219 13.9834 14.3087 13.9846 15.7449 14.0358L15.6506 9.11457Z"
          fill={color}
        />
        <path
          d="M14.4227 7.08755C14.5725 6.97377 14.6987 6.83123 14.794 6.66808C14.8894 6.50493 14.9521 6.32436 14.9785 6.1367C15.0048 5.94903 14.9945 5.75794 14.9479 5.57433C14.9013 5.39073 14.8194 5.21821 14.707 5.06664C14.234 4.4295 13.2109 4.12316 12.578 4.60072C11.9451 5.07829 11.8219 6.26055 12.2949 6.90002C12.7679 7.53949 13.7932 7.56628 14.4227 7.08755Z"
          fill={color}
        />
      </svg>
    </CustomGroupAvatar>
  );
};

export const VideoAvatar = ({ color }: AvatarProps) => {
  return (
    <CustomVideoAvatar>
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0934 6.77308C14.4456 6.64771 7.92001 4.89731 5.61237 4.55136C3.30473 4.2054 3.23439 3.69441 2.67414 6.5398C2.357 8.15214 1.81402 11.9656 1.78564 13.6477C1.75726 15.3299 2.04355 15.322 3.57869 15.6743C5.55314 16.1265 12.3576 17.2263 13.5509 17.4358C15.4093 17.7627 15.7882 18.1134 16.167 15.9409C16.4879 14.1032 16.6606 11.6736 16.8643 10.1469C17.2653 7.13173 16.8161 7.10793 15.0934 6.77308ZM15.0724 16.4836L2.71487 14.3079L3.70949 5.26231L15.8449 7.84427L15.0724 16.4836ZM11.5049 11.1959L7.57695 13.3303L8.1372 7.93473L11.5049 11.1959ZM3.56758 2.98346L2.81606 0.263433L3.46639 0L3.96988 2.8565L3.56758 2.98346ZM2.72721 4.00862L0.640462 1.74564L1.23403 0.980732L3.06163 3.61189L2.72721 4.00862ZM2.10402 5.36705L0 4.28475L0.191275 3.74043L2.23606 4.91953L2.10402 5.36705Z"
          fill={color}
        />
      </svg>
    </CustomVideoAvatar>
  );
};

export const BoxAvatar = ({ color }: AvatarProps) => {
  return (
    <CustomBoxAvatar>
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.9792 12.1054L14.7484 9.23042L9.31397 7.43506L10.9207 4.59383L13.9034 4.12541C14.2647 4.06858 14.7276 3.65084 14.7322 3.12253C14.7289 2.93067 14.6791 2.74537 14.5902 2.59421C14.3478 2.15036 13.9819 1.77409 13.6887 1.39014C13.3955 1.00619 13.1208 0.525482 12.7341 0.288968C12.2663 0.0324197 11.7559 -0.0555154 11.2543 0.034025L4.52713 0.722065C3.3636 0.722065 2.89842 1.93535 2.7703 2.2333L0.305887 8.08624C0.105111 8.56123 -0.000241921 9.09706 4.17137e-07 9.64201V12.4925C0.00114039 12.6529 0.0358766 12.8098 0.100309 12.9454C0.164741 13.0811 0.256318 13.1901 0.364756 13.2604L3.88072 15.6885C4.17682 15.9353 4.52779 16.0389 4.87457 15.9818L7.04117 15.6286L13.8411 14.4905C14.611 14.4905 14.9134 13.8762 14.9861 12.9425C15.0064 12.6639 15.004 12.3833 14.9792 12.1054ZM12.2886 0.722065L14.0997 3.30375L10.7903 3.76449C10.7672 4.16534 10.4232 4.44946 10.0977 4.39571C9.8455 4.34663 9.61148 4.19229 9.4294 3.95493L6.72605 4.33581L4.52713 1.58211L12.2886 0.722065ZM5.19431 11.3206C4.93162 11.3682 4.6643 11.3065 4.42901 11.144L0.904964 8.81114L8.79569 7.42892L8.70335 10.0398L9.83109 10.5236L5.19431 11.3206Z"
          fill={color}
        />
      </svg>
    </CustomBoxAvatar>
  );
};
