import { Button, ButtonProps as MuiButtonProps } from '@mui/material';
import {
  ExperienceCardFieldsFragment,
  ExperienceMode,
  ExperienceType,
} from '../../../generated/graphql';

const getColor = (experience: ExperienceCardFieldsFragment) => {
  if (experience.type === ExperienceType.Box) {
    return '#FFCF36';
  } else if (experience.mode === ExperienceMode.InPerson) {
    return '#A3DCAB';
  } else if (experience.mode === ExperienceMode.Online) {
    return '#EBCEE2';
  } else {
    return '#FFCF36';
  }
};

export type ButtonProps = MuiButtonProps & {
  experience?: ExperienceCardFieldsFragment;
  margin?: string;
};
const StatusButton = ({ children, experience, margin, ...rest }: ButtonProps) => {
  return (
    <Button
      component="span"
      variant="contained"
      tabIndex={-1}
      style={{
        minWidth: 80,
        borderRadius: 86,
        color: '#231727',
        backgroundColor: getColor(experience!),
        textTransform: 'none',
        fontFamily: 'var(--font-work-sans), sans-serif',
        fontSize: 10,
        height: 24,
        margin: margin ? margin : '0 0 0 6.48px',
        padding: '0 10px',
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};
export default StatusButton;
