import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { formatDateTime } from '../../../../../utils/formatDateTime';
import TimeBlobSvg from '../../images/time-blob.svg';

const StyledTimeBlob = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;
const Time = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin: 2px;
  color: #4c3b51;
`;

const ContentContainer = styled.div``;

const Label = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13.3654px;
  line-height: 1.5;
`;

const Content = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13.3654px;
  line-height: 148%;
`;

interface ExperienceAvailabilityTimeProps {
  time: string;
}

export const ExperienceAvailabilityTime = ({
  time,
}: ExperienceAvailabilityTimeProps): React.ReactElement => {
  return (
    <Time>
      <StyledTimeBlob>
        <TimeBlobSvg />
      </StyledTimeBlob>
      <ContentContainer>
        <Label>Next availability:</Label>
        <Content>{formatDateTime(DateTime.fromISO(time))}</Content>
      </ContentContainer>
    </Time>
  );
};
