import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { ForwardedRef, forwardRef, MouseEvent } from 'react';
import {
  ExperienceCardFieldsFragment,
  ExperienceMode,
  ExperienceStatus,
  ExperienceType,
} from '@/generated/graphql';
import { MediaContainer } from '../../media-container/media-container';
import { ExperienceTags } from '../experience-tags/experience-tags';
import { ExperienceAvailabilityTime } from './components/experience-availability-time/experience-availability-time';
import { ExperienceEventTime } from './components/experience-event-time/experience-event-time';
import { theme } from '@/app/common/providers/theme-provider/theme';

type MainImageProps = {
  mode: ExperienceMode;
};
type StyleLinkProps = {
  grid: string;
};

const modePlaceholderColor = {
  [ExperienceMode.Online]: '#FFF3DC',
  [ExperienceMode.InPerson]: '#1AA16B',
};

const MainImage = styled(MediaContainer)<MainImageProps>`
  width: 100%;
  height: 48.45%;
  background-color: ${({ mode }) => modePlaceholderColor[mode] || '#FFCF36'};
`;
const BoxImage = styled(MediaContainer)`
  width: 100%;
  height: 48.45%;
  background-color: '#FFCF36';
`;

const HostImage = styled(MediaContainer)`
  border-radius: 60% 70% 70% 60%;
  width: 55px;
  height: 55px;
  margin-top: -23px;
  left: 26px;
  border: 3px solid #ffffff;
  z-index: 10;
  object-fit: cover;
  position: absolute;
  background-color: #b4b4b4;
`;

const EventCard = styled.div`
  width: 100%;
  height: 100%;
`;

const BGImage = styled.img`
  position: absolute;
  height: 30px;
  width: 100%;
  top: 45%;
`;
const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 50%;
  margin-top: -1px;
  padding: 30px 20px 10px 20px;
  z-index: 5;
`;
const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0;
  align-items: center;
`;
const HostName = styled.span`
  font-family: var(--font-cooper-light), sans-serif;
  color: #4d66b1;
  font-style: normal;
  font-weight: 300;
  font-size: 15.5643px;
  line-height: 159.3%;
  margin-top: 10px;
`;
const Text = styled.span`
  font-family: var(--font-work-sans), sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15.5643px;
  color: #767676;
`;
const EventName = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 17.5px;
  color: #231727;
  font-weight: 700;
  line-height: 132%;
  height: 50px;
`;

const StyledLink = styled.a<StyleLinkProps>`
  display: block;
  background: #ffffff;
  overflow: hidden;
  border-radius: 18px;
  box-shadow: 0px 3.59159px 7px rgba(0, 0, 0, 0.2);
  text-decoration: none !important;
  transform: translateY(0);
  transition: transform ease-in-out 0.2s;
  width: 100% !important;
  height: 323px;
  position: relative;
  @media (max-width: 768px) {
    height: ${props => (props.grid === 'true' ? '374px' : '314px')};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 207, 54, 0.7);
  }

  &:hover {
    transform: translateY(-10px);
  }
`;
const modePatterns: Record<ExperienceMode, string> = {
  [ExperienceMode.Online]: '/card/online.png',
  [ExperienceMode.InPerson]: '/card/inPerson.png',
};

const getModePattern = (mode: ExperienceMode = ExperienceMode.Online) => {
  if (mode !== undefined) {
    return modePatterns[mode];
  }
  return '/card/box.png';
};

export type ExperienceCardProps = {
  experience: ExperienceCardFieldsFragment;
  showNextTime?: boolean;
  noCarousel?: boolean;
  link?: string;
  customActions?: React.ReactNode;
};

const ExperienceCardWithRef = (
  {
    experience,
    showNextTime: showNextTimeOverride,
    noCarousel,
    link,
    customActions,
    ...otherProps
  }: ExperienceCardProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) => {
  const router = useRouter();
  if (!experience) {
    return null;
  }
  const id = experience.id;
  const image = experience.images?.edges[0]?.node?.file;
  const title = experience.title
    ? experience?.title?.length > 45
      ? `${experience?.title?.slice(0, 40)}...`
      : experience?.title
    : 'untitled';
  const type = experience.type;
  const nextEvent = type === ExperienceType.Event && experience.nextEvent;
  const showNextTimeDefault = type === ExperienceType.Event || type === ExperienceType.Schedule;
  const showNextTime =
    typeof showNextTimeOverride !== 'undefined' ? showNextTimeOverride : showNextTimeDefault;
  const isCarousel = typeof noCarousel !== 'undefined' ? noCarousel : false;
  const nextAvailability = type === ExperienceType.Schedule && experience.nextAvailability;
  const hostName = experience?.host?.profile?.nickname;
  const hostImage = experience?.host?.profile?.avatar;
  const status = experience.status;
  const mode = experience.mode;
  const pendingReview = status === ExperienceStatus.PendingReview;
  const realLink = link ? link : `/experiences/${id}`;

  let card;
  if (type === ExperienceType.Box) {
    card = (
      <EventCard style={{ cursor: 'pointer' }}>
        <BoxImage>
          {image?.url && (
            <Image
              src={image?.url}
              alt="Experience Image"
              fill
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          )}
        </BoxImage>
        <BGImage src={'/card/box.png'} />
        <ExperienceTags experience={experience} />
        <HostImage>
          {hostImage?.url && (
            <Image
              src={hostImage?.url}
              alt="Host Profile Image"
              fill
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          )}
        </HostImage>
        <Content>
          <Typography>
            <HostName>{hostName}</HostName> <Text>is hosting</Text>
          </Typography>
          <EventName title={experience.title || undefined}>{title}</EventName>
          <Actions>
            {showNextTime && nextEvent && <ExperienceEventTime time={nextEvent} />}
            {showNextTime && nextAvailability && (
              <ExperienceAvailabilityTime time={nextAvailability} />
            )}
            {!!customActions && customActions}
          </Actions>
        </Content>
      </EventCard>
    );
  } else {
    card = (
      <EventCard style={{ cursor: 'pointer' }}>
        <MainImage mode={mode!}>
          {image?.url && (
            <Image
              src={image?.url}
              alt="Experience Image"
              fill
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          )}
        </MainImage>
        <BGImage src={getModePattern(mode || undefined)} />
        <ExperienceTags experience={experience} />
        <HostImage>
          {hostImage?.url && (
            <Image
              src={hostImage?.url}
              alt="Host Profile Image"
              fill
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          )}
        </HostImage>
        <Content>
          <Typography>
            <HostName>{hostName}</HostName> <Text>is hosting</Text>
          </Typography>
          <EventName title={experience.title || undefined}>{title}</EventName>
          <Actions>
            {showNextTime && nextEvent && <ExperienceEventTime time={nextEvent} />}
            {showNextTime && nextAvailability && (
              <ExperienceAvailabilityTime time={nextAvailability} />
            )}
            {!!customActions && customActions}
          </Actions>
        </Content>
      </EventCard>
    );
  }

  return (
    <StyledLink
      ref={ref}
      href={realLink}
      {...otherProps}
      grid={isCarousel.toString()}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        router.push(realLink);
      }}
    >
      {pendingReview ? (
        <>
          <Global
            styles={css`
              .experience-tooltip {
                background-color: ${theme.palette.common.cream};
                color: ${theme.palette.common.midnight};
              }
            `}
          />
          <Tooltip
            title="This experience is being reviewed!"
            placement="bottom"
            classes={{
              tooltip: 'experience-tooltip',
            }}
          >
            {card}
          </Tooltip>
        </>
      ) : (
        card
      )}
    </StyledLink>
  );
};

const ExperienceCard = forwardRef(ExperienceCardWithRef);

export default ExperienceCard;
