import { ExperienceType } from '../generated/graphql';

export const experienceTypeLabel: Record<
  Exclude<ExperienceType, ExperienceType.Undefined>,
  string
> = {
  [ExperienceType.Box]: 'box',
  [ExperienceType.Video]: 'video',
  [ExperienceType.Event]: 'group',
  [ExperienceType.Schedule]: 'one-on-one',
};
