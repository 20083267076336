import { createTheme } from '@mui/material/styles';
import './theme';
import { CooperLtBT, WorkSans } from './fonts';

let theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#EB5757',
    },
    background: {
      default: '#fff',
    },
    common: {
      midnight: '#231727',
      yellow: '#FFCF31',
      cream: '#FDF3DE',
      black: '#251628',
      plum: '#705C74',
      midPurple: '#472B48',
    },
  },
  typography: {
    fontFamily: [WorkSans.style.fontFamily, CooperLtBT.style.fontFamily, 'sans-serif'].join(','),
    h1: {
      fontFamily: [CooperLtBT.style.fontFamily, 'sans-serif'].join(','),
      fontSize: 50,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: '#FDF3DE',
            opacity: 0.3,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 20,
          position: 'relative',
          transform: 'translateZ(0)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          height: 80,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        label: {
          fontSize: '1rem',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        anchorOriginTopCenter: {
          top: '100px !important',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#231727',
          color: 'white',
          fontSize: 14,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0, 0.75)',
        },
      },
    },
  },
});

export { theme };
